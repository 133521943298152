
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import base from "@/api/base.js";
import XLSX from "xlsx";
import * as Yup from "yup";
import ElConfigProvider from "element-plus";
import ko from "element-plus/es/locale/lang/ko";

interface NewAddressData {
  targetTitle: string;
  assign: string;
  dueDate: string;
  targetDetails: string;
  tags: Array<string>;
}

export default defineComponent({
  name: "newcheckitemversions",
  components: {
    ErrorMessage,
    Field,
    Form,
    ElConfigProvider,
  },

  data() {
    const validation = Yup.object().shape({
      versiontitle: Yup.string().required("필수값입니다."),
      brand: Yup.string().required("필수값입니다."),
      checkCase: Yup.string().required("필수값입니다."),
      useYn: Yup.string().required("필수값입니다."),
      // checkDate: Yup.string().required("필수값입니다."),
    });

    return {
      checkBox: [],
      checkItemVersion: {},
      checkItems: [],
      basicItem: [],
      test: [],
      brands: [],
      searchcode: "brand_nm",
      searchdata: "",
      disabledDate(time) {
        const nowDate = new Date();
        const yesterDate = nowDate.getTime() - 1 * 24 * 60 * 60 * 1000;
        return yesterDate > time.getTime();
      },
      result: [],
      validation,
      ko,
    };
  },
  methods: {
    getListData() {
      const data = {
        [this.searchcode]: this.searchdata,
        brand_id: this.checkItemVersion.brand,
        check_item_use_yn: 1,
      };

      base
        .getCheckItemList(data)
        .then((res) => {
          console.log(res);
          this.checkItems = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    async submitForm() {
      this.checkItemVersion.check_item = this.checkBox;
      this.checkItemVersion.check_ver_start_date =
        this.targetData.checkStartDate;
      this.checkItemVersion.check_ver_end_date = this.targetData.checkEndDate;
      console.log(this.checkItemVersion);
      await base
        .newCheckVersion(this.checkItemVersion)
        .then((res) => {
          console.log(res);

          if (res.status == 201) {
            alert("신규 점검 기준을 등록했습니다.");
            window.history.back();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    excelDownFunc() {
      this.excelCheckItem();
      const ws = XLSX.utils.json_to_sheet(this.basicItem);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "checkitem");
      XLSX.writeFile(wb, "CheckItemList.xlsx");
    },

    excelCheckItem() {
      const newitem = this.basicItem.map((e) => {
        e.brand = e.brand.brand_nm;
        return e;
      });
    },

    readFile(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const tmpResult = {};

      reader.onload = (e) => {
        let data: string | ArrayBuffer = e.target.result;
        data = new Uint8Array(data as ArrayBuffer);

        const excelFile = XLSX.read(data, { type: "array" });

        excelFile.SheetNames.forEach(function (sheetName) {
          const roa = XLSX.utils.sheet_to_json(excelFile.Sheets[sheetName], {
            header: 1,
          });
          if (roa.length) tmpResult[sheetName] = roa;
        });
        this.result = tmpResult;
        console.log(tmpResult);
        this.transData();
      };
      reader.readAsArrayBuffer(file);
    },

    transData() {
      const data = this.result.checkitem;
      console.log(data);
      for (let i = 1; i < data.length; i++) {
        this.checkBox.push(data[i][0]);
      }
      console.log("checkbox", this.checkBox);
    },
  },

  created() {
    base
      .getBrandList()
      .then((res) => {
        console.log(res);
        this.brands = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  setup() {
    const targetData = ref<NewAddressData>({
      targetTitle: "",
      assign: "",
      dueDate: "",
      targetDetails: "",
      tags: ["important", "urgent"],
    });

    return {
      targetData,
    };
  },
});
